import revive_payload_client_rXfAZCpZ3v from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DOob2WD0gh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tFeje4mXC0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yjqPsyywKF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Yas741MLQn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kCjGKmviw5 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_th82oOL6Rn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8PzcKQ0xvO from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@2.79.2_typescript@5.7.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_bWUZZBPnLd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_rollup@2.79.2_sass@1.83.4_tsx@4.19.2_typescript@5.7.3_vite@6.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_SxEo2fVQQI from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@6.0.11_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_PBrsMaeQD4 from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.2_vite@6.0.11_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_mqQQeyf3x2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@2.79.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import slideovers_hNPchZnHYk from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.3_vite@6.0.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_rASqgmBvhU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.3_vite@6.0.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_t9uEE51WJK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_qrcode@1.5.4_rollup@2.79.2_typescript@5.7.3_vite@6.0.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_BgDihNaN7u from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@2.79.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vHUQ6NzvS3 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_rollup@2.79.2_vite@6.0.11_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import plugin_client_jYXhOY9ZdH from "/opt/buildhome/repo/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@2.79.2_vue@3.5.13/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_wofILYXtuU from "/opt/buildhome/repo/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_Kc8eAfVqeE from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.3.1_rollup@2.79.2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_YJGsTbn2VI from "/opt/buildhome/repo/plugins/calendar.client.js";
import content_GArnI6Y6ho from "/opt/buildhome/repo/plugins/content.js";
import tracker_client_GsGCjPbvL8 from "/opt/buildhome/repo/plugins/tracker.client.js";
import vconsole_client_Zu3Hk7iamm from "/opt/buildhome/repo/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "/opt/buildhome/repo/plugins/wallet.client.js";
export default [
  revive_payload_client_rXfAZCpZ3v,
  unhead_DOob2WD0gh,
  router_tFeje4mXC0,
  payload_client_yjqPsyywKF,
  navigation_repaint_client_Yas741MLQn,
  check_outdated_build_client_kCjGKmviw5,
  chunk_reload_client_th82oOL6Rn,
  plugin_vue3_8PzcKQ0xvO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bWUZZBPnLd,
  pwa_icons_SxEo2fVQQI,
  pwa_client_PBrsMaeQD4,
  nuxt_plugin_mqQQeyf3x2,
  slideovers_hNPchZnHYk,
  modals_rASqgmBvhU,
  colors_t9uEE51WJK,
  plugin_client_BgDihNaN7u,
  plugin_vHUQ6NzvS3,
  floating_vue_EIcJ7xiw0h,
  plugin_client_jYXhOY9ZdH,
  plugin_wofILYXtuU,
  plugin_Kc8eAfVqeE,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  tracker_client_GsGCjPbvL8,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]